import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "rolescope-based-permissions"
    }}>{`Role/scope-based permissions`}</h1>
    <p><a parentName="p" {...{
        "href": "https://useauth.dev"
      }}><inlineCode parentName="a">{`useAuth`}</inlineCode></a>{` comes with built-in support for authorization.`}</p>
    <p>{`Authorization is handy when you have different types of authenticated users. Admins are a common example. Or trial and paid users.`}</p>
    <p>{`Debate rages whether role-based or scope-based permissions are The One True Scalable Way. `}<inlineCode parentName="p">{`useAuth`}</inlineCode>{` doesn't care. There is no technical difference between roles and scopes, they're both a string you attach to the user 🙃`}</p>
    <h2 {...{
      "id": "1-attach-roles-or-scopes-to-users"
    }}>{`1. Attach roles or scopes to users`}</h2>
    <p>{`Roles and scopes are part of user data. They come from your auth provider. Every provider handles it differently.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/roles-auth0"
        }}>{`Add roles or scopes with Auth0`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/roles-netlify-identity"
        }}>{`Add roles with Netlify Identity`}</a></li>
    </ul>
    <h2 {...{
      "id": "2-check-user-permissions"
    }}>{`2. Check user permissions`}</h2>
    <p><inlineCode parentName="p">{`useAuth`}</inlineCode>{`'s abstraction layer handles reading the correct properties for you.`}</p>
    <p>{`To check for a single role or scope, use the `}<inlineCode parentName="p">{`isAuthorized`}</inlineCode>{` method with a string.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function AuthorizedComponent() {
    const { isAuthorized } = useAuth();

    // verify user is an admin
    if (isAuthorized("admin")) {
        return <AdminUI />;
    } else {
        return null;
    }
}
`}</code></pre>
    <p><inlineCode parentName="p">{`isAuthorized`}</inlineCode>{` verifies that the user is authenticated and that their role or scope meta data contains the `}<inlineCode parentName="p">{`"admin"`}</inlineCode>{` string.`}</p>
    <p>{`To check for multiple roles, you can pass an array.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function AuthorizedComponent() {
    const { isAuthorized } = useAuth();

    // verify user has at least 1 of these scopes
    if (isAuthorized(["moderate", "admin"])) {
        return <ModeratorUI />;
    } else {
        return null;
    }
}
`}</code></pre>
    <p><inlineCode parentName="p">{`isAuthorized`}</inlineCode>{` checks for `}<em parentName="p">{`some`}</em>{` roles or scopes matching your user. If you need to check for `}<em parentName="p">{`every`}</em>{` role, run `}<inlineCode parentName="p">{`isAuthorized`}</inlineCode>{` multiple times. The OR case seemed more common when designing this API :)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      